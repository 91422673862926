import React, { useEffect } from 'react';
import Button, { ButtonSize, ButtonVariant } from 'components/Button';
import {
  ButtonsTranslationKeys,
  MenuTranslationKeys,
  NoOffersTranslationKeys,
  TranslationNameSpaces,
} from 'enums/Translation';

import { ApplicationLabelName } from 'enums/ApplicationLabelId';
import { DeclineReason } from 'enums/DeclineReason';
import DisclosuresBox from 'components/DisclosuresBox';
import { LayoutSize } from 'components/PageLayout/PageLayout';
import Menu from 'components/Menu';
import PageLayout from 'components/PageLayout';
import Paragraph from 'components/Paragraph';
import ParticipatingLenderInfo from 'components/InformationBox/ParticipatingLenderInfo';
import Title from 'components/Title';
import { TypographySize } from 'enums/TypographySize';
import { updateApplication } from 'thunks';
import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppRoutes } from 'routes/AppRoutes';
import { getApplicationProcessData } from 'handlers/selectors';
import {
  getApplicationStatusIdByEnvironment,
  matchApplicationStatusIdByEnvironment,
} from 'utils/applicationStatusIdHelpers';
import { ApplicationStatusName } from 'enums/ApplicationStatusName';
import { getApplicationLabelIdByEnvironment } from 'utils/applicationLabelsHelpers';

import styles from './NoOffers.module.scss';

const NoOffers = () => {
  const { applicationId } = useParams();
  const { t: translate } = useTranslation([
    TranslationNameSpaces.Buttons,
    TranslationNameSpaces.Menu,
    TranslationNameSpaces.OffersPage,
  ]);
  const {
    applicationInfo: { statusId, labelsIds },
  } = useAppSelector(getApplicationProcessData);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!matchApplicationStatusIdByEnvironment(statusId, ApplicationStatusName.Rejected)) {
      dispatch(
        updateApplication({
          applicationId: applicationId!,
          params: {
            statusId: getApplicationStatusIdByEnvironment(ApplicationStatusName.Rejected),
            declineReasons: [DeclineReason.NoOffers],
            labelsIds: [
              ...labelsIds,
              getApplicationLabelIdByEnvironment(ApplicationLabelName.NoOffers),
            ],
          },
        }),
      );
    }
  }, []);

  return (
    <>
      <Menu activeMenuItem={MenuTranslationKeys.Applications} />
      <PageLayout layoutSize={LayoutSize.Small}>
        <div className={styles.pageWrapper}>
          <Title variant="h1" className={styles.noOffersTitle}>
            {translate(NoOffersTranslationKeys.Title, { ns: TranslationNameSpaces.OffersPage })}
          </Title>
          <Paragraph variant={TypographySize.Large} className={styles.noOffersDescription}>
            {translate(NoOffersTranslationKeys.Description, {
              ns: TranslationNameSpaces.OffersPage,
            })}
          </Paragraph>
          <Paragraph variant={TypographySize.Large} className={styles.noOffersSubDescription}>
            {translate(NoOffersTranslationKeys.SubDescription, {
              ns: TranslationNameSpaces.OffersPage,
            })}
          </Paragraph>
          <Button
            variant={ButtonVariant.Secondary}
            size={ButtonSize.Large}
            className={styles.noOffersButton}
            onClick={() => navigate(AppRoutes.Applications)}
          >
            {translate(ButtonsTranslationKeys.TakeToAccountButton, {
              ns: TranslationNameSpaces.Buttons,
            })}
          </Button>
        </div>
        <DisclosuresBox />
        <ParticipatingLenderInfo />
      </PageLayout>
    </>
  );
};

export default NoOffers;
